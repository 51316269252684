import { Component } from "react";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

type DirectContactListProps = {};

type DirectContactListState = {};

export default class DirectContactList extends Component<
  DirectContactListProps,
  DirectContactListState
> {
  state = {};

  render() {
    return (
      <span className="flex flex-col items-center md:items-start">
        <span className="mt-2 text-center">Reach out for any inquiry:</span>
        <span className="flex flex-col items-center md:items-start mt-2">
          {/* <span className="flex space-x-2">
            <WhatsAppIcon />
            <p>+94772192828</p>
          </span> */}
          <span className="flex space-x-2">
            <EmailIcon />
            <p>yashith.chanu@gmail.com</p>
          </span>
        </span>
      </span>
    );
  }
}
