import { Component } from "react";

type TitleProps = {
  title: string;
  titleIcon?: React.ReactNode;
  centerTitle?: boolean;
};

type TitleState = {};

export default class Title extends Component<TitleProps, TitleState> {
  state = {};

  render() {
    return (
      <span
        className={`flex space-x-3 ${
          this.props.centerTitle
            ? "justify-center"
            : "justify-center sm:justify-start"
        } items-center`}
      >
        <h1 className="font-heading text-center">{this.props.title}</h1>
        {this.props.titleIcon ? (
          <span className="rounded-[50%] border border-accent-navy-200 p-4">
            {this.props.titleIcon}
          </span>
        ) : null}
      </span>
    );
  }
}
