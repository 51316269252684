import { Component } from "react";
import Logo from "./../assets/Personal-logo.jpeg";

type LogoIdentifierProps = {
  hideName?: boolean;
};

type LogoIdentifierState = {};

export default class LogoIdentifier extends Component<
  LogoIdentifierProps,
  LogoIdentifierState
> {
  state = {};

  render() {
    return (
      <div className="flex items-center space-x-2 w-full">
        <img
          src={Logo}
          alt="yashithchanuka"
          className="w-16 sm:w-20 h-16 sm:h-20 rounded-[50%] border border-grey-6"
        />
        {!this.props.hideName ? (
          <h1 className="font-special-italic !text-2xl md:!text-3xl lg:!text-4xl !font-extrabold">
            Yashith.C Liyanaarachchi
          </h1>
        ) : null}
      </div>
    );
  }
}
