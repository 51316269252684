import { Component } from "react";

type ReviewCardProps = {
  reviewerName: string;
  reviewerPosition: string;
  reviewerAvatar: React.ReactNode;
  review: string;
  reviewerLocation: string;
};

type ReviewCardState = {
  isExpanded: boolean;
};

export default class ReviewCard extends Component<
  ReviewCardProps,
  ReviewCardState
> {
  constructor(props: ReviewCardProps) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState((prevState: ReviewCardState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  render() {
    const reviewText = this.props.review;
    const words = reviewText.split(" ");
    const wordCount = words.length;
    let shortenedText = "";

    if (wordCount > 60) {
      shortenedText = words.slice(0, 60).join(" ");
    }

    return (
      <div className="bg-white text-accent-navy-200 w-full rounded-md px-6 py-4 shadow-lg h-full">
        <span className="flex justify-center">{this.props.reviewerAvatar}</span>
        {wordCount > 50 && !this.state.isExpanded ? (
          <p className="mt-2 border-y border-y-grey-4 py-2">
            {shortenedText}...
            <span
              className="ml-2 font-bold underline cursor-pointer"
              onClick={this.toggleExpanded}
            >
              Show More
            </span>
          </p>
        ) : wordCount > 50 && this.state.isExpanded ? (
          <p className="mt-2 border-y border-y-grey-4 py-2">
            {reviewText}
            <span
              className="ml-2 font-bold underline cursor-pointer"
              onClick={this.toggleExpanded}
            >
              Show less
            </span>
          </p>
        ) : (
          <p className="mt-2 border-y border-y-grey-4 py-2">{reviewText}...</p>
        )}

        <span className="flex space-x-2 mt-2">
          <span className="flex flex-col">
            <p className="font-heading">{this.props.reviewerName}</p>
            <p className="font-bold">{this.props.reviewerPosition}</p>
            <p className="font-bold">({this.props.reviewerLocation})</p>
          </span>
        </span>
      </div>
    );
  }
}
