import { Component } from "react";
import ReviewCard from "./ReviewCard";
import Slider from "react-slick";
import Title from "./Title";

type ReviewsPanelProps = {
  title?: string;
  reviewDetails: {
    id: number;
    reviewerName: string;
    reviewerPosition: string;
    reviewerLocation: string;
    review: string;
    reviewerAvatar: React.ReactNode;
  }[];
};

type ReviewsPanelState = {};

export default class ReviewsPanel extends Component<
  ReviewsPanelProps,
  ReviewsPanelState
> {
  state = {};

  render() {
    let settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      swipe: true,
      infinite: true,
      centerMode: true,
      centerPadding: "50px",
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            arrows: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
            centerMode: true,
            centerPadding: "20px",
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            centerMode: true,
            centerPadding: "10px",
          },
        },
      ],
    };
    return (
      <div className="w-full h-full py-4  mt-2">
        <Title title="What People say ?" centerTitle={true} />

        <div className="mt-4">
          <Slider {...settings}>
            {this.props.reviewDetails.map((review) => (
              <div key={review.id} className="px-1 sm:px-2">
                <ReviewCard
                  key={review.id}
                  reviewerName={review.reviewerName}
                  reviewerPosition={review.reviewerPosition}
                  reviewerLocation={review.reviewerLocation}
                  review={review.review}
                  reviewerAvatar={review.reviewerAvatar}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}
