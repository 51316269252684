import { Component } from "react";
import CardsPanel from "../components/CardsPanel";
import Hero from "../components/Hero";
import ReviewsPanel from "../components/ReviewsPanel";
import StackPanel from "../components/StackPanel";
import TypeWriter from "../components/TypeWriter";
import ComputerIcon from "@mui/icons-material/Computer";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ArticleIcon from "@mui/icons-material/Article";
import DevicesIcon from "@mui/icons-material/Devices";
import DatasetIcon from "@mui/icons-material/Dataset";
import Avatar from "@mui/material/Avatar";

// reviewers
import Reviewer1 from "./../assets/reviewer-1.jpeg";
import Reviewer2 from "./../assets/reviewer-2.jpeg";
import Reviewer3 from "./../assets/reviewer-3.jpeg";
import Reviewer4 from "./../assets/reviewer-4.jpeg";

// stack logos
import JavaLogo from "./../assets/tech-logos/java-logo.png";
import SpringBootLogo from "./../assets/tech-logos/springboot-logo.png";
import AngularLogo from "./../assets/tech-logos/angular-logo.png";
import ReactLogo from "./../assets/tech-logos/react-logo.png";
import PythonLogo from "./../assets/tech-logos/python-logo.png";
import MySQLLogo from "./../assets/tech-logos/MySQL-Logo.png";
import NodeJSLogo from "./../assets/tech-logos/nodejs-logo.png";
import TailwindcssLogo from "./../assets/tech-logos/tailwindcss-logo.png";
import VueLogo from "./../assets/tech-logos/Vue.js-logo.png";
import JavaScriptLogo from "./../assets/tech-logos/JavaScript-logo.png";
import TypeScriptLogo from "./../assets/tech-logos/typescript-logo.png";
import ShopifyLogo from "./../assets/tech-logos/shopify-logo.png";
import FirebaseLogo from "./../assets/tech-logos/firebase-logo.png";
import AWSLogo from "./../assets/tech-logos/aws-logo.png";
import YearGap from "../components/YearGap";

type HomeProps = {};

type HomeState = {
  doingDetails: {
    title: string;
    description: string;
    icon: React.ReactNode;
  }[];
  reviewDetails: {
    reviewerName: string;
    reviewerPosition: string;
    reviewerLocation: string;
    review: string;
    reviewerAvatar: React.ReactNode;
  }[];
  stackDetails: {
    id: number;
    name?: string;
    logo: React.ReactNode;
  }[];
};

export default class Home extends Component<HomeProps, HomeState> {
  state = {
    doingDetails: [
      {
        id: 1,
        title: "Web Application Developments",
        description:
          "Power up your online presence with a custom web app. As a web app developer, I create dynamic solutions that enhance your user experience and drive results. From sleek designs to cutting-edge functionality, let me help you stay ahead of the competition with a custom web application.",
        icon: <DevicesIcon />,
      },
      {
        id: 2,
        title: "Desktop Application Developments",
        description:
          "Developing powerful desktop applications that streamline your workflow is my specialty. With a focus on functionality and user experience, I create custom solutions that optimize your business processes. Let's work together to build an application that meets your unique needs and helps your business thrive.",
        icon: <ComputerIcon />,
      },
      {
        id: 3,
        title: "Mobile Developments",
        description:
          "Elevate your business with a custom mobile app. As a mobile app developer, I specialize in creating engaging and intuitive solutions that streamline operations and drive results. From sleek designs to powerful functionality, let me help you unlock the potential of mobile technology for your business.",
        icon: <StayCurrentPortraitIcon />,
      },
      {
        id: 4,
        title: "Shopify Themes Developments",
        description:
          "Maximize your e-commerce potential with a custom Shopify theme. As a Shopify theme developer, I create stunning designs that capture your brand's essence and drive conversions. With seamless integrations and user-friendly interfaces, let me help you enhance your online store and take your business to the next level.",
        icon: <AddShoppingCartIcon />,
      },
      {
        id: 5,
        title: "Web Crawling",
        description:
          "Explore the depths of the internet with a skilled web crawler. I specialize in extracting valuable data and insights to help businesses gain a competitive edge. With advanced technologies and a detail-oriented approach, let me help you uncover hidden opportunities and drive growth for your business.",
        icon: <DatasetIcon />,
      },
      {
        id: 6,
        title: "Tutoring",
        description:
          "Unlock your coding potential with personalized guidance from a software development tutor. I offer hands-on mentorship to help you achieve your goals and become a skilled programmer. From beginners to advanced coders, let me help you succeed in the exciting world of software development!",
        icon: <ArticleIcon />,
      },
    ],
    reviewDetails: [
      {
        id: 1,
        reviewerName: "Mr. Eranga Dulshan",
        reviewerPosition: "Chief Operating Officer at AkvaSoft",
        reviewerLocation: "Mathugama, Sri Lanka",
        review:
          "I am happy to recommend Yashith who was an integral part of our team during his time at Akvasoft. He worked with me in a mobile development project and he was always willing to go the extra mile to ensure that our mobile app was of the highest quality. He consistently demonstrated a deep understanding of mobile development and a passion for delivering quality software. His technical knowledge, communication skills, and commitment to delivering quality software make Yashith an excellent asset to any team.",
        reviewerAvatar: (
          <Avatar alt="YashithChanuka_reviewer_Eranga" src={Reviewer2} />
        ),
      },
      {
        id: 2,
        reviewerName: "Mr. Gimantha Jayampath",
        reviewerPosition: "High Ticket Digital Marketer",
        reviewerLocation: "Galle, Sri Lanka",
        review:
          "Yashith is one of my good friend I've met in my university Intern life. He is very helpful, open-minded,hard working and responsive person. I had the pleasure of working with Yashith for five months in some projects, collaborating on several project teams. He is full of new technological ideas in software. He has good knowledge in Programming. Yashith is a Highly Skilled Developer and certainly can get my full recommendation.",
        reviewerAvatar: (
          <Avatar alt="YashithChanuka_reviewer_Gimantha" src={Reviewer1} />
        ),
      },
      {
        id: 3,
        reviewerName: "Mr. Adil Rashid",
        reviewerPosition: "Account Executive at AIIC_Riyadh KSA",
        reviewerLocation: "Kolkata, India",
        review:
          "I genuinely want to take a moment to thank Yashith for the services I've gotten from him. His guidance and support throughout my difficult situations at my work have been helped me to solve those technical difficulties. I sincerely admire and appreciate his work ethic and talent. He is very organized, diligent and the most important thing is quickly responsive to my inquiries. That is what I most wanted when I was in some technical difficulties. Really looking forward to keep in touch with Yashith and I can recommend him as a really good professional at his work.",
        reviewerAvatar: (
          <Avatar alt="YashithChanuka_reviewer_Adil" src={Reviewer3} />
        ),
      },
      {
        id: 4,
        reviewerName: "Mr. Vishwa Ranaweera",
        reviewerPosition: "Software Engineet at AkvaSoft",
        reviewerLocation: "Galle, Sri Lanka",
        review:
          "Yashith is one of my good colleagues in AkvaSoft. Even though sometimes we worked in different teams in the company, he supported me a lot whenever I wanted some guidance or support. I really appreciate his supportiveness and commitments to the work. He has an excellent knowledge in Java, SpringBoot, JS Frameworks/Libraries and also in shopify. Also he can adjust to a new technology/framework quickly. He is very motivated and dedicated guy to his work. I am really happy to recommend him and he is an excellent asset to any team.",
        reviewerAvatar: (
          <Avatar alt="YashithChanuka_reviewer_Vishwa" src={Reviewer4} />
        ),
      },
    ],
    stackDetails: [
      {
        id: 1,
        logo: <img src={JavaLogo} alt="YashithChanuka_JavaLogo" />,
      },
      {
        id: 2,
        logo: <img src={SpringBootLogo} alt="YashithChanuka_SpringBootLogo" />,
      },
      {
        id: 3,
        logo: <img src={AngularLogo} alt="YashithChanuka_AngularLogo" />,
      },
      {
        id: 4,
        logo: <img src={ReactLogo} alt="YashithChanuka_ReactLogo" />,
      },
      {
        id: 5,
        logo: <img src={PythonLogo} alt="YashithChanuka_PythonLogo" />,
      },
      {
        id: 6,
        logo: <img src={MySQLLogo} alt="YashithChanuka_MySQLLogo" />,
      },
      {
        id: 7,
        logo: <img src={NodeJSLogo} alt="YashithChanuka_NodeJSLogo" />,
      },
      {
        id: 8,
        logo: (
          <img src={TailwindcssLogo} alt="YashithChanuka_TailwindcssLogo" />
        ),
      },
      {
        id: 9,
        logo: <img src={VueLogo} alt="YashithChanuka_VueLogo" />,
      },
      {
        id: 10,
        logo: <img src={JavaScriptLogo} alt="YashithChanuka_JavaScriptLogo" />,
      },
      {
        id: 11,
        logo: <img src={TypeScriptLogo} alt="YashithChanuka_TypeScriptLogo" />,
      },
      {
        id: 12,
        logo: <img src={ShopifyLogo} alt="YashithChanuka_ShopifyLogo" />,
      },
      {
        id: 13,
        logo: <img src={FirebaseLogo} alt="YashithChanuka_FirebaseLogo" />,
      },
      {
        id: 14,
        logo: <img src={AWSLogo} alt="YashithChanuka_AWSLogo" />,
      },
    ],
  };

  render() {
    return (
      <div className="min-h-screen px-4 md:px-6">
        <Hero
          description={
            <p className="my-3 font-body text-center sm:text-justify">
              You can call me as <strong>Yashith</strong> or{" "}
              <strong>Yash</strong> &#x1F609;. I am a motivated,enthusiastic,
              and hardworking Software Engineer with{" "}
              <YearGap isExperience={true} /> years of experience. I am
              passionate about learning cutting-edge technologies/frameworks and
              working by applying them to modern real-world applications. My
              best satisfaction is always to give a better technical solution to
              the world by elaborating my skills and abilities. If I could help
              someone with my skills and knowledge, that always makes me happy.
              So… Why are we still waiting? Let’s begin…
            </p>
          }
          showGreeting={true}
          typeWriterComponent={
            <TypeWriter
              texts={[
                "Yashith Chanuka.",
                "a Software Engineer.",
                "a Web Developer.",
                "a Mobile Apps Developer.",
                "a Shopify Themes Developer.",
                "a Online Tutor.",
              ]}
              delay={150}
              preElement={<h1 className="font-heading mt-2">I'm</h1>}
            />
          }
          showPlatFormList={true}
          showButton={true}
          availableMainImage={true}
        />
        <CardsPanel
          title="What I'm Doing ?"
          cardDetails={this.state.doingDetails}
        />
        <StackPanel stackDetails={this.state.stackDetails} />
        <ReviewsPanel
          title="What My Colleagues/Clients say ?"
          reviewDetails={this.state.reviewDetails}
        />
      </div>
    );
  }
}
