import { Component } from "react";

type YearGapProps = {
  isAge?: boolean;
  isExperience?: boolean;
};

type YearGapState = {
  gap: string;
};

export default class YearGap extends Component<YearGapProps, YearGapState> {
  constructor(props: YearGapProps) {
    super(props);
    this.state = {
      gap: "",
    };
  }

  componentDidMount(): void {
    this.figureOutGap();
  }

  figureOutGap() {
    if (this.props.isAge) {
      // Here 1997 and 9 are my birth year and month
      let difference = this.calculateDifferenceInYearsAndMonths(
        1997,
        9,
        new Date().getFullYear(),
        new Date().getMonth() + 1
      );
      this.setState({ gap: difference.years + "" });
    } else if (this.props.isExperience) {
      // Here 2019 and 2 are career starting year and month
      let difference = this.calculateDifferenceInYearsAndMonths(
        2019,
        2,
        new Date().getFullYear(),
        new Date().getMonth() + 1
      );

      if (difference.months === 0) {
        this.setState({ gap: " " + difference.years });
      } else if (difference.months < 6) {
        this.setState({ gap: " more than " + difference.years });
      } else if (difference.months >= 6 && difference.months < 11) {
        this.setState({ gap: difference.years + " 1/2" });
      } else {
        this.setState({ gap: " almost " + (difference.years + 1) });
      }
    } else {
    }
  }

  calculateDifferenceInYearsAndMonths(
    startYear: number,
    startMonth: number,
    endYear: number,
    endMonth: number
  ): { years: number; months: number } {
    const monthsPerYear = 12;
    let totalMonths =
      (endYear - startYear) * monthsPerYear + (endMonth - startMonth);
    let years = Math.floor(totalMonths / monthsPerYear);
    let months = totalMonths % monthsPerYear;
    return { years, months };
  }

  render() {
    return <span>{this.state.gap}</span>;
  }
}
