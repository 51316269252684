import { Component } from "react";
import Hero from "../components/Hero";
import PersonalDetailsPanel from "../components/PersonalDetailsPanel";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
// import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import IJSELogo from "./../assets/ijse-logo.jpeg";
import AkvasoftLogo from "./../assets/Akvasoft-logo.jpeg";
import KingstonLogo from "./../assets/kingston-logo.jpeg";
import DharmasokaLogo from "./../assets/dharmasoka-logo.jpeg";
import { Avatar } from "@mui/material";
import RatingSection from "../components/RatingSection";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import YearGap from "../components/YearGap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type AboutProps = {};

type AboutState = {
  badgeTexts: string[];
  workExperienceDetails: {
    id: number;
    title: string;
    description: React.ReactNode;
    period: string;
    organization: {
      logo?: React.ReactNode;
      name: string;
    };
  }[];
  educationDetails: {
    id: number;
    title: string;
    description?: React.ReactNode;
    period: string;
    organization: {
      logo?: React.ReactNode;
      name: string;
    };
  }[];
  certificationDetails: {
    id: number;
    title: string;
    description?: string;
  }[];
  skillDetails: {
    id: number;
    title?: string;
    progressValue: number;
  }[];
};

export default class About extends Component<AboutProps, AboutState> {
  state = {
    badgeTexts: [
      "Working Experiences",
      "Education",
      "Certifications",
      "Professional Skills",
      "Hobbies",
    ],
    workExperienceDetails: [
      {
        id: 1,
        title: "Software Engineer & Technical Instructor",
        period: "August 2021 - Present",
        organization: {
          name: "Self-Employed",
        },
        description: (
          <span className="mt-2 flex flex-col space-y-2">
            <span>
              As a freelancing software engineer and technical instructor, I
              have been working with some small to large groups to bring their
              own business or something else to the internet or digital world.
              Web developments, Mobile developments, e-commerce platforms, web
              crawling bots are the main areas which I have covered till now as
              a freelancing developer. Apart from these, I have provided
              professional services, guidance to my clients as an instructor and
              consultant.
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Web & Desktop application Developments.</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Mobile application Developments.</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>e-commerce platform developments.</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Web scraping / data crawling bots.</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Online tutoring on software developments.</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Technical consultancies for the professionals.</span>
            </span>
          </span>
        ),
      },
      {
        id: 2,
        title: "Visiting Lecturer",
        period: "February 2023 - Present",
        organization: {
          logo: (
            <Avatar
              alt="YashithChanuka_IJSE"
              src={IJSELogo}
              className="border border-grey-4"
            />
          ),
          name: "Institute of Java & Software Engineering - IJSE (Galle, Sri Lanka)",
        },
        description: (
          <span className="mt-2 flex flex-col space-y-2">
            {/* <span>
              During this position, my job is to train and practice the students
              for their upcoming industrial internship programs.The module
              includes some modern trending technologies, frameworks such as
              React, Spring Boot etc. The most special thing is, I was invited
              for the lectures as a visiting lecturer in the same institute
              where I got my foundation of software engineering. Following are
              the main objectives of my lecturing module.
            </span> */}
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>
                Training students for full-stack developments before they enter
                into internships.
              </span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>
                Practicing students for Javascript frameworks/libraries.
              </span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>
                To build some modern web applications from the scratch using
                trending technologies/frameworks. (in-class)
              </span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>
                Practicing students for solving problems, researching on
                something etc
              </span>
            </span>
          </span>
        ),
      },
      {
        id: 3,
        title: "Software Engineer",
        period: "August 2019 - October 2022",
        organization: {
          logo: (
            <Avatar
              alt="YashithChanuka_Akvasoft"
              src={AkvasoftLogo}
              className="border border-grey-4"
            />
          ),
          name: "AkvaSoft (pvt) Ltd. (Galle, Sri Lanka)",
        },
        description: (
          <span className="mt-2 flex flex-col space-y-2">
            <span>
              While working as a Software Engineer, I have worked in some
              different teams for different projects in different domains. Under
              the agile methodology, I had to guide some junior developers as
              well. One of my favorite things was, I had the opportunity to work
              with foreign clients and foreign co-workers. Not only the client
              base projects, but also I have been worked as the main engineer of
              some products in the company. From the technical perspective, many
              kinds of technologies were used for both local and foreign
              projects. Some of them are below.
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>
                Front-end technologies : Angular, React, React-Native (For
                Mobile Developments), VanillaJS, TypeScript, TailwindCSS
              </span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>
                Back-end technologies : Java, Spring Boot, Python,
                Node.js/Express.js
              </span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Databases : MySQL, PostgreSQL, Dynamo DB</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>
                For the shopify themes developments : Shopify Liquid, Vue,
                VanillaJS, TailwindCSS
              </span>
            </span>
          </span>
        ),
      },
      // {
      //   id: 4,
      //   title: "Trainee Software Engineer",
      //   period: "February 2019 - July 2019",
      //   organization: {
      //     logo: (
      //       <Avatar
      //         alt="YashithChanuka_Akvasoft"
      //         src={AkvasoftLogo}
      //         className="border border-grey-4"
      //       />
      //     ),
      //     name: "AkvaSoft (pvt) Ltd. (Galle, Sri Lanka)",
      //   },
      //   description: (
      //     <span className="mt-2 flex flex-col space-y-2">
      //       <span>
      //         During these 6 months, I have been exposed to industry through
      //         some interesting projects. I could gather many skills, not only
      //         the technical skills, but also the non-technical interpersonal
      //         skills which someone wants to survive in the industry. I have been
      //         involved in some POC (Proof Of Concept) projects while working in
      //         some client based projects. Java + Spring Boot, Angular, Python
      //         were the main technologies, languages which I have used during
      //         this period.
      //       </span>
      //     </span>
      //   ),
      // },
    ],
    educationDetails: [
      {
        id: 1,
        title: "Bsc (Hons) Computer Science in Software Engineering",
        period: "2021-2023",
        organization: {
          logo: (
            <Avatar
              alt="YashithChanuka_Kingston"
              src={KingstonLogo}
              className="border border-grey-4"
            />
          ),
          name: "Kingston University (London, UK)",
        },
      },
      {
        id: 2,
        title: "Graduate Diploma in Software Engineering",
        period: "2018-2019",
        organization: {
          logo: (
            <Avatar
              alt="YashithChanuka_IJSE"
              src={IJSELogo}
              className="border border-grey-4"
            />
          ),
          name: "Institute of Java & Software Engineering - IJSE (Galle, Sri Lanka)",
        },
      },
      {
        id: 3,
        title: "G/Dharmasoka College",
        period: "2008-2016",
        organization: {
          logo: (
            <Avatar
              alt="YashithChanuka_Dharmasoka"
              src={DharmasokaLogo}
              className="border border-grey-4"
            />
          ),
          name: "(Ambalangoda, Sri Lanka)",
        },
      },
    ],
    certificationDetails: [
      {
        id: 1,
        title: "ABSD - Advanced Business Solution Developer",
        organization: {
          logo: (
            <Avatar
              alt="YashithChanuka_IJSE"
              src={IJSELogo}
              className="border border-grey-4"
            />
          ),
          name: "Institute of Java & Software Engineering - IJSE (Galle, Sri Lanka)",
        },
      },
      {
        id: 2,
        title: "CMJD - Comprehensive Master Java Developer",
        organization: {
          logo: (
            <Avatar
              alt="YashithChanuka_IJSE"
              src={IJSELogo}
              className="border border-grey-4"
            />
          ),
          name: "Institute of Java & Software Engineering - IJSE (Galle, Sri Lanka)",
        },
      },
    ],
    skillDetails: [
      {
        id: 1,
        title: "React",
        progressValue: 95,
      },
      {
        id: 2,
        title: "Java",
        progressValue: 95,
      },
      {
        id: 3,
        title: "Spring Boot",
        progressValue: 95,
      },
      {
        id: 4,
        title: "Angular",
        progressValue: 95,
      },
      {
        id: 5,
        title: "MySQL",
        progressValue: 95,
      },
      {
        id: 6,
        title: "TypeScript",
        progressValue: 95,
      },
      {
        id: 7,
        title: "JavaScript",
        progressValue: 95,
      },
      {
        id: 8,
        title: "React Native",
        progressValue: 85,
      },
      {
        id: 9,
        title: "Python",
        progressValue: 85,
      },

      {
        id: 10,
        title: "Vue",
        progressValue: 80,
      },

      {
        id: 11,
        title: "PostgreSQL",
        progressValue: 80,
      },
      {
        id: 12,
        title: "Firebase",
        progressValue: 80,
      },
      {
        id: 13,
        title: "Shopify",
        progressValue: 80,
      },
    ],
  };

  render() {
    return (
      <div className="min-h-screen px-4 md:px-6">
        <Hero
          title="Who am I ?"
          description={
            <span className="flex flex-col space-y-2 my-3 font-body text-center sm:text-justify">
              <p>
                This is Yashith Chanuka. I am a <YearGap isAge={true} /> years
                old, graduated software engineer based in Ambalangoda, Sri
                Lanka. I have <YearGap isExperience={true} /> years of
                experience in the industry as a Software Engineer. Within these
                <YearGap isExperience={true} /> years, I have worked on so many
                interesting projects (Both local & foreign projects) based on
                different kinds of technologies and frameworks. I am always a
                dedicated and motivated person to serve you the best with my
                skills and knowledge.
              </p>
              <p>
                Here, you will see my working experiences, education
                qualifications, technical skills etc. You will get a clear
                picture about me after going through those sections on this
                page.
              </p>
            </span>
          }
          availableMainImage={true}
        />
        <PersonalDetailsPanel
          title="Work Experiences"
          titleIcon={<WorkIcon />}
          personalDetails={this.state.workExperienceDetails}
        />
        <PersonalDetailsPanel
          title="Education"
          titleIcon={<SchoolIcon />}
          personalDetails={this.state.educationDetails}
        />
        {/* <PersonalDetailsPanel
          title="Certifications"
          titleIcon={<WorkspacePremiumIcon />}
          personalDetails={this.state.certificationDetails}
        /> */}
        <RatingSection
          title="Technical Skills"
          titleIcon={<EmojiEventsIcon />}
          ratingDetails={this.state.skillDetails}
        />
      </div>
    );
  }
}
