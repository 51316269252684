import { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import CommonButton from "./CommonButton";
import LogoIdentifier from "./LogoIdentifier";
import Drawer from "./Drawer";

type HeaderProps = {};

type HeaderState = {};

export default class Header extends Component<HeaderProps, HeaderState> {
  state = {};

  render() {
    const activeLink =
      "font-heading cursor-pointer border-b-2 border-b-accent-navy-200";
    const normalLink = "font-heading cursor-pointer";

    return (
      <div className="fixed top-0 h-24 w-full z-10 bg-accent-navy-75 mb-24">
        <div className="flex justify-between h-full items-center p-4">
          <Link to={"/"}>
            <LogoIdentifier />
          </Link>

          <nav className="hidden md:flex justify-around w-2/5">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              About Me
            </NavLink>
            <NavLink
              to="/projects"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Projects
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Contact
            </NavLink>
          </nav>
          <div className="hidden md:flex">
            <Link to={"/hire-me"}>
              <CommonButton title="Hire Me" />
            </Link>
          </div>
          <div className="flex md:hidden">
            <Drawer />
          </div>
        </div>
      </div>
    );
  }
}
