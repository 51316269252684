import { Component } from "react";
import ButtonLink from "./ButtonLink";

type PersonalDetailCardProps = {
  title: string;
  description?: React.ReactNode;
  period?: string;
  organization?: {
    logo?: React.ReactNode;
    name: string;
  };
  projectUrl?: string;
  techStack?: string;
  creditsGiven?: string;
};

type PersonalDetailCardState = {};

export default class PersonalDetailCard extends Component<
  PersonalDetailCardProps,
  PersonalDetailCardState
> {
  state = {};

  render() {
    return (
      <span className="bg-white text-left text-accent-navy-200 w-full rounded-md px-6 py-4 border border-accent-navy-75 shadow-lg">
        <h4 className="font-heading border-b border-b-grey-4">
          {this.props.title}
        </h4>

        <span className="flex space-x-3 mt-2 items-center">
          {this.props.organization?.logo}
          <p>{this.props.organization?.name}</p>
        </span>

        {this.props.period ? <p>Duration : {this.props.period}</p> : null}

        {this.props.description ? this.props.description : null}

        {this.props.techStack ? (
          <p className="mt-2">
            Technology Stack : <span>{this.props.techStack}</span>
          </p>
        ) : null}

        {this.props.projectUrl ? (
          <span className="flex mt-2">
            <a href={this.props.projectUrl} target={"_blank"} rel="noreferrer">
              <ButtonLink text="Show Project" />
            </a>
          </span>
        ) : null}

        {this.props.creditsGiven ? (
          <h6 className="mt-2 !font-normal">{this.props.creditsGiven}</h6>
        ) : null}
      </span>
    );
  }
}
