import { Component } from "react";
import ContactForm from "../components/ContactForm";
import Hero from "../components/Hero";
import PlatformList from "../components/PlatformList";

type ContactProps = {};

type ContactState = {};

export default class Contact extends Component<ContactProps, ContactState> {
  state = {};

  render() {
    return (
      <div className="min-h-screen px-4 md:px-6">
        <Hero
          title="Why do you want to contact me ?"
          description={
            <p className="my-3 font-body text-center sm:text-justify">
              Are you struggling with some technical things ? No need to worry
              anymore. Feel free to contact me here. I am always happy to
              support anyone who needs some guidance or support. Don't hesitate
              to ask me anything you want.
            </p>
          }
        />
        <PlatformList />
        <ContactForm />
      </div>
    );
  }
}
