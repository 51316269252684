import { Component } from "react";

type StackCardProps = {
  name?: string;
  logo: React.ReactNode;
};

type StackCardState = {};

export default class StackCard extends Component<
  StackCardProps,
  StackCardState
> {
  state = {};

  render() {
    return (
      <div className="w-24 h-24 flex justify-center items-center p-4 shadow-lg bg-white rounded-[50%]">
        {this.props.logo}
      </div>
    );
  }
}
