import { Component } from "react";

type GreetingProps = {};

type GreetingState = {
  greeting: string;
};

export default class Greeting extends Component<GreetingProps, GreetingState> {
  state = {
    greeting: "",
  };

  componentDidMount(): void {
    this.getCurrentGreetingType();
  }

  getCurrentGreetingType = () => {
    let currentHour = new Date().getHours();
    if (currentHour < 12) {
      this.setState({ greeting: "Morning" });
    } else if (currentHour >= 12 && currentHour < 17) {
      this.setState({ greeting: "Afternoon" });
    } else {
      this.setState({ greeting: "Evening" });
    }
  };

  render() {
    return (
      <span>
        <h3 className="font-heading">
          Hey! Good {this.state.greeting}!
          {this.state.greeting === "Morning" ||
          this.state.greeting === "Afternoon" ? (
            <span>&nbsp; &#x1F31E;</span>
          ) : (
            <span>&nbsp; &#x1F31B;</span>
          )}
        </h3>
      </span>
    );
  }
}
