import { Component } from "react";
import MainImage from "./../assets/main-image.png";
import CommonButton from "./CommonButton";
import Greeting from "./Greeting";
import PlatformList from "./PlatformList";
import Title from "./Title";
import { Link } from "react-router-dom";

type HeroProps = {
  title?: string;
  description: React.ReactNode;
  showGreeting?: boolean;
  typeWriterComponent?: React.ReactNode;
  showPlatFormList?: boolean;
  showButton?: boolean;
  availableMainImage?: boolean;
};

type HeroState = {};

export default class Hero extends Component<HeroProps, HeroState> {
  state = {};

  render() {
    return (
      <div className="flex w-full h-full">
        <div
          className={`flex flex-col justify-center pt-6  ${
            this.props.availableMainImage ? "w-full sm:w-2/3" : "w-full"
          }`}
        >
          {this.props.showGreeting ? <Greeting /> : null}

          {this.props.title ? <Title title={this.props.title} /> : null}

          {this.props.typeWriterComponent
            ? this.props.typeWriterComponent
            : null}

          {this.props.availableMainImage ? (
            <div className="w-full sm:hidden">
              <img src={MainImage} alt="logo" className="w-full h-full" />
            </div>
          ) : null}

          {this.props.description}

          {this.props.showPlatFormList ? <PlatformList /> : null}

          {this.props.showButton ? (
            <span className="flex justify-center sm:justify-start mt-4">
              <Link to={"/about"}>
                <CommonButton title="More Info" />
              </Link>
            </span>
          ) : null}
        </div>
        {this.props.availableMainImage ? (
          <div className="hidden sm:block sm:w-1/3">
            <img
              src={MainImage}
              alt="yashith chanuka"
              className="w-full h-full"
            />
          </div>
        ) : null}
      </div>
    );
  }
}
