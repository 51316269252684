import { Component } from "react";
import Slider from "react-slick";
import StackCard from "./StackCard";
import Title from "./Title";

type StackPanelProps = {
  stackDetails: {
    id: number;
    name?: string;
    logo: React.ReactNode;
  }[];
};

type StackPanelState = {};

export default class StackPanel extends Component<
  StackPanelProps,
  StackPanelState
> {
  state = {};

  render() {
    let settings = {
      dots: false,
      autoplay: true,
      slidesToShow: 6,
      autoplaySpeed: 1000,
      infinite: true,
      arrows: false,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
            centerPadding: "10px",
          },
        },
      ],
    };
    return (
      <div className="w-full py-4">
        <Title title="Technology Stack" centerTitle={true} />

        <div className="mt-4">
          <Slider {...settings}>
            {this.props.stackDetails.map((stack) => (
              <StackCard key={stack.id} logo={stack.logo} />
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}
