import { Component } from "react";

type ButtonLinkProps = { text: string };

type ButtonLinkState = {};

export default class ButtonLink extends Component<
  ButtonLinkProps,
  ButtonLinkState
> {
  state = {};

  render() {
    return (
      <button className="px-4 flex py-2 border border-accent-navy-200 rounded-full font-bold w-auto hover:bg-accent-navy-200 hover:text-white cursor-pointer">
        <h6>{this.props.text}</h6>
      </button>
    );
  }
}
