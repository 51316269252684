import { Component } from "react";

type CommonButtonProps = {
  title: string;
  disabled?: boolean;
  submitButton?: boolean;
};

type CommonButtonState = {};

export default class CommonButton extends Component<
  CommonButtonProps,
  CommonButtonState
> {
  state = {};

  render() {
    return (
      <button
        disabled={this.props.disabled ? true : false}
        type={this.props.submitButton ? "submit" : "button"}
        className="bg-accent-navy-200 w-auto text-sm sm:text-base text-white px-6 py-2 rounded-md"
      >
        {this.props.title}
      </button>
    );
  }
}
