import { Component } from "react";
import DirectContactList from "./DirectContactList";
import LogoIdentifier from "./LogoIdentifier";
import PlatformList from "../components/PlatformList";
import FooterRights from "./FooterRights";

type FooterProps = {};

type FooterState = {};

export default class Footer extends Component<FooterProps, FooterState> {
  state = {};

  render() {
    return (
      <div className="w-full bg-accent-navy-75 ">
        <div className="flex flex-col items-center md:items-baseline m p-4">
          <div className="py-2 md:flex md:justify-between space-x-3 md:w-full">
            <LogoIdentifier />

            <span className="flex flex-col space-y-3 justify-end-end">
              <PlatformList />
              <DirectContactList />
            </span>
          </div>
        </div>
        <FooterRights />
      </div>
    );
  }
}
