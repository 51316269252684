import { Component } from "react";
import Hero from "../components/Hero";
import HireMeForm from "../components/HireMeForm";
import YearGap from "../components/YearGap";

type HireMeProps = {};

type HireMeState = {};

export default class HireMe extends Component<HireMeProps, HireMeState> {
  state = {};

  render() {
    return (
      <div className="min-h-screen px-4 md:px-6">
        <Hero
          title="Why do you want to hire me ?"
          description={
            <span className="my-3 flex flex-col space-y-3 text-center sm:text-justify">
              <p>
                Are you looking for someone to get professional service ? Let’s
                discuss it. Feel free to contact me for any inquiry. I’m already
                a professional with <YearGap isExperience={true} /> years of
                experience. Let's work together. Cheers for success !!!
              </p>

              <span className="text-left">
                <h4 className="text-center sm:text-left font-heading">
                  5 Easy steps to Hire Me
                </h4>
                <span className="flex items-start space-x-2 mt-2">
                  <p>1.</p>
                  <p>
                    Go through other pages (Home, About Me, Projects) & get an
                    idea about me.
                  </p>
                </span>
                <span className="flex items-start space-x-2">
                  <p>2.</p>
                  <p>
                    If you think I will suit your needs, don't hesitate to give
                    me a message with your problem in brief through the below
                    forum. (Please give your valid contact email there.)
                  </p>
                </span>
                <span className="flex items-start space-x-2">
                  <p>3.</p>
                  <p>
                    Let me contact you and let's have a friendly chat about your
                    problem.
                  </p>
                </span>
                <span className="flex items-start space-x-2">
                  <p>4.</p>
                  <p>
                    Let me show you some achievable solutions for your problem.
                  </p>
                </span>
                <span className="flex items-start space-x-2">
                  <p>5.</p>
                  <p>
                    If you are satisfied with those solutions, Let's work
                    together till success.
                  </p>
                </span>
              </span>
            </span>
          }
        />
        <HireMeForm />
      </div>
    );
  }
}
