import { Component } from "react";
import Card from "./Card";

type CardsPanelProps = {
  title?: string;
  cardDetails: {
    id: number;
    title: string;
    description: string;
    icon: React.ReactNode;
  }[];
};

type CardsPanelState = {};

export default class CardsPanel extends Component<
  CardsPanelProps,
  CardsPanelState
> {
  state = {};

  render() {
    return (
      <div className="w-full py-4 mt-2">
        <h1 className="font-heading text-center text-accent-navy-200">
          {this.props.title}
        </h1>
        <span className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3  mt-4">
          {this.props.cardDetails.map((card) => (
            <Card
              key={card.id}
              title={card.title}
              description={card.description}
              icon={card.icon}
            />
          ))}
        </span>
      </div>
    );
  }
}
