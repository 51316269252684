import { Component } from "react";
import Hero from "../components/Hero";
import PersonalDetailsPanel from "../components/PersonalDetailsPanel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

type ProjectsProps = {};

type ProjectsState = {
  projectDetails: {
    id: number;
    title: string;
    description?: React.ReactNode;
    projectUrl?: string;
    techStack: string;
    creditsGiven?: string;
  }[];
};

export default class Projects extends Component<ProjectsProps, ProjectsState> {
  state = {
    projectDetails: [
      {
        id: 1,
        title: "Personal website - yashithchanuka.com",
        description: (
          <span className="mt-2 flex flex-col space-y-2">
            <p>
              You are already on this project now. This is a fully responsive
              web site which describes my services, own experiences, skills and
              so on. Even though there are some platforms to maintain a
              portfolio or any other details, if you could have your own
              platform to expose yourself to the world it would be great. That
              is the major objective of this project.
            </p>
          </span>
        ),
        projectUrl: "https://yashithchanuka.com/",
        techStack: "React, Typescript, TailwindCSS, Firebase",
      },
      {
        id: 2,
        title: "CRM for supporting team",
        description: (
          <span className="mt-2 flex flex-col space-y-2">
            <p>
              This is like a bridge between customers and service providers.
              This web application makes the customer and service providers
              relationship more easy. It includes mass audience messaging,
              emailing, handling customer details with more security,
              importing/exporting details through security process and many more
              user-friendly features.
            </p>
          </span>
        ),
        techStack: "React, Spring Boot, MySQL plus additional services",
      },
      {
        id: 3,
        title: "E-Human Resources Management & Tasks Tracking System",
        description: (
          <span className="mt-2 flex flex-col space-y-2">
            <p>
              This is the final year individual project at my university.
              According to the project, this system offers many user-friendly
              features to manage human resources of a company or organization.
              Following are few of the main features that the system provides.
            </p>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Separate logins for Admins, Managers and Employees.</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Descriptive Dashboard facility for the users.</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Attendance Tracking</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Tasks Tracking</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Maintaining own working calendar</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Recruitement Management</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Payroll Handling</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Documentation Handling</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Generating Specific Reports</span>
            </span>
            <span className="flex space-x-2">
              <CheckCircleIcon />
              <span>Leaves Policy Management</span>
            </span>
          </span>
        ),
        techStack: "Angular, Spring Boot, MySQL, AWS",
      },

      {
        id: 4,
        title: "Administration Dashboards - Online stores",
        description: (
          <span className="mt-2 flex flex-col space-y-2">
            <p>
              Nowadays most of the businesses are coming online. In case of
              this, the admin dashboards are becoming a huge impact for the
              business world. Through these projects, owners/admins of the
              businesses can get a good understanding about their ongoing
              businesses. Descriptive dashboards with data charts and graphs,
              inventory management, income & expense management, orders
              management and many more features were included in these projects.
            </p>
          </span>
        ),
        techStack: "Angular, Spring Boot, TailwindCSS, MySQL",
      },
      {
        id: 5,
        title: "Optical Shop Management System",
        description: (
          <span className="mt-2 flex flex-col space-y-2">
            <p>
              This is a stand-alone project which manages all the operations in
              an optical shop including inventory, records handling, customer
              details management, billing, income & expenses handling, dashboard
              facility etc.
            </p>
          </span>
        ),
        techStack: "Java, JavaFX, MySQL",
      },
      {
        id: 6,
        title: "ShuttlePath",
        projectUrl:
          "https://play.google.com/store/apps/details?id=com.shuttlepath.customerapp",
        techStack: "React-Native, Express.js, Dynamo DB",
        creditsGiven:
          "*Credit goes to respective project owners & associated with AkvaSoft.",
      },
      {
        id: 7,
        title: "AkvaFin Financial Projects",
        techStack: "Angular, Spring Boot, MySQL, AWS",
        creditsGiven:
          "*Credit goes to respective project owners & associated with AkvaSoft.",
      },
      {
        id: 8,
        title: "Berliner Bags",
        projectUrl: "https://berliner-bags.com/",
        techStack: "Shopify Liquid, Vue.js, TailwindCSS",
        creditsGiven:
          "*Credit goes to respective project owners & associated with AkvaSoft.",
      },
      {
        id: 9,
        title: "Aeyla",
        projectUrl: "https://www.aeyla.co.uk/",
        techStack: "Shopify Liquid, Vue.js, TailwindCSS",
        creditsGiven:
          "*Credit goes to respective project owners & associated with AkvaSoft.",
      },
      {
        id: 10,
        title: "Crate Adventure Bookings",
        projectUrl: "https://crateadventure.com/",
        techStack: "Angular, Spring Boot, MySQL",
        creditsGiven:
          "*Credit goes to respective project owners & associated with AkvaSoft.",
      },
    ],
  };

  render() {
    return (
      <div className="min-h-screen px-4 md:px-6">
        <Hero
          title="Projects"
          description={
            <p className="my-3 font-body text-center sm:text-justify">
              Since 2019, I have been involved in many projects in different
              domains such as financial projects, mobile apps for public
              audience, e-commerce sites, stand-alone applications for specific
              clients and also some commercial booking sites as well. Throughout
              my journey, I have been working in different teams with different
              developers for different projects. Following are a few of the
              interesting projects which I have mainly been involved in
              recently. Some of them are associated with some companies.
            </p>
          }
        />
        <PersonalDetailsPanel personalDetails={this.state.projectDetails} />
      </div>
    );
  }
}
