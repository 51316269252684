import { Component } from "react";

type TypeWriterProps = {
  texts: string[];
  delay?: number;
  preElement?: React.ReactNode;
};

type TypeWriterState = {
  currentText: string;
  isErasing: boolean;
  textIndex: number;
};

export default class TypeWriter extends Component<
  TypeWriterProps,
  TypeWriterState
> {
  private intervalId: NodeJS.Timeout | null = null;

  constructor(props: TypeWriterProps) {
    super(props);
    this.state = {
      currentText: "",
      isErasing: false,
      textIndex: 0,
    };
  }

  public componentDidMount() {
    this.startTyping();
  }

  public componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  private startTyping() {
    let currentIndex = 0;
    const { texts, delay } = this.props;
    const { isErasing, textIndex } = this.state;
    this.intervalId = setInterval(() => {
      const text = texts[textIndex];
      if (isErasing) {
        this.setState({
          currentText: text.slice(0, currentIndex),
        });
        currentIndex--;
        if (currentIndex < 0) {
          this.setState({
            isErasing: false,
            textIndex: (textIndex + 1) % texts.length,
          });
          clearInterval(this.intervalId as NodeJS.Timeout);
          setTimeout(() => {
            this.startTyping();
          }, delay || 1000);
        }
      } else {
        this.setState({
          currentText: text.slice(0, currentIndex),
        });
        currentIndex++;
        if (currentIndex > text.length) {
          this.setState({
            isErasing: true,
          });
          clearInterval(this.intervalId as NodeJS.Timeout);
          setTimeout(() => {
            this.startTyping();
          }, delay || 1000);
        }
      }
    }, delay || 100);
  }

  render() {
    return (
      <span className="flex space-x-2">
        {this.props.preElement}
        <h1 className="font-heading mt-2">{this.state.currentText}</h1>
      </span>
    );
  }
}
