import { TextField } from "@material-ui/core";
import { Component } from "react";
import CommonButton from "./CommonButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Title from "./Title";
import emailjs from "@emailjs/browser";
import Snackbar from "@mui/material/Snackbar";

type ContactFormProps = {};

type ContactFormState = {
  name: string;
  email: string;
  subject: string;
  message: string;
  isValidEmail: boolean;
  successAlertOpen: boolean;
  errorAlertOpen: boolean;
  disableSubmitButton: boolean;
};

export default class ContactForm extends Component<
  ContactFormProps,
  ContactFormState
> {
  constructor(props: ContactFormProps) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      isValidEmail: false,
      successAlertOpen: false,
      errorAlertOpen: false,
      disableSubmitButton: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.openAlert = this.openAlert.bind(this);
  }

  handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (name === "email") {
      if (emailRegex.test(value)) {
        this.setState({ email: value, isValidEmail: true });
      } else {
        this.setState({ email: value, isValidEmail: false });
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  handleSubmit(event: any) {
    event.preventDefault();

    if (this.state.isValidEmail) {
      this.setState({ disableSubmitButton: true });
      this.sendEmail(event);
    }
  }

  clearState() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  sendEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const templateParams = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message,
    };

    // add here SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY from EMAILJS web-site (Check IDs in relevant google doc)
    emailjs
      .send(
        "service_zouqy4e",
        "template_ph3p2dx",
        templateParams,
        "Pjckgr7Zzd8p3c4XW"
      )
      .then(
        (result) => {
          this.clearState();
          this.setState({ disableSubmitButton: false });
          this.openAlert("success");
        },
        (error) => {
          this.setState({ disableSubmitButton: false });
          this.openAlert("error");
        }
      );
  }

  closeAlert() {
    this.setState({ successAlertOpen: false, errorAlertOpen: false });
  }

  openAlert(alertType: string) {
    if (alertType === "success") {
      this.setState({ successAlertOpen: true, errorAlertOpen: false });
    } else if (alertType === "error") {
      this.setState({ errorAlertOpen: true, successAlertOpen: false });
    }
  }

  render() {
    return (
      <div className="w-full py-4">
        <Snackbar
          className="bg-main-primary-100"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.successAlertOpen}
          autoHideDuration={7000}
          onClose={this.closeAlert}
          message="Thanks for contacting me. I'll get back to you soon !"
        />
        <Snackbar
          className="bg-main-primary-100"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.errorAlertOpen}
          autoHideDuration={5000}
          onClose={this.closeAlert}
          message="Something went wrong. Please try again or contact me through another way."
        />
        <div className="bg-white rounded-lg px-4 md:px-10 lg:px-44 py-8">
          <Title
            title="Contact Me"
            titleIcon={<MailOutlineIcon />}
            centerTitle={true}
          />

          <form onSubmit={this.handleSubmit}>
            <span className="grid grid-cols-1 gap-3  mt-4">
              <TextField
                label="Name"
                variant="outlined"
                placeholder="Your Name"
                name="name"
                value={this.state.name}
                onChange={this.handleInputChange}
                required
              />
              <TextField
                label="Email"
                variant="outlined"
                placeholder="Your Email (Please make sure to give your contact email)"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
              {this.state.email.length > 0 && !this.state.isValidEmail ? (
                <span className="text-error">Please enter a valid email</span>
              ) : null}

              <TextField
                label="Subject"
                variant="outlined"
                placeholder="Write the subject here."
                name="subject"
                value={this.state.subject}
                onChange={this.handleInputChange}
                required
              />
              <TextField
                label="Message"
                variant="outlined"
                placeholder="Write your message here."
                name="message"
                value={this.state.message}
                onChange={this.handleInputChange}
                multiline
                minRows={10}
                maxRows={Infinity}
                required
              />
              <CommonButton
                disabled={this.state.disableSubmitButton}
                title="Submit"
                submitButton={true}
              />
            </span>
          </form>
        </div>
      </div>
    );
  }
}
