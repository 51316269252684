import { Component } from "react";
import PersonalDetailCard from "./PersonalDetailCard";
import Title from "./Title";

type PersonalDetailsPanelProps = {
  title?: string;
  titleIcon?: React.ReactNode;
  personalDetails: {
    id: number;
    title: string;
    description?: React.ReactNode;
    period?: string;
    organization?: {
      logo?: React.ReactNode;
      name: string;
    };
    projectUrl?: string;
    techStack?: string;
    creditsGiven?: string;
  }[];
};

type PersonalDetailsPanelState = {};

export default class PersonalDetailsPanel extends Component<
  PersonalDetailsPanelProps,
  PersonalDetailsPanelState
> {
  state = {};

  render() {
    return (
      <div className="w-full py-4 mt-2">
        {this.props.title ? (
          <Title
            title={this.props.title}
            titleIcon={this.props.titleIcon}
            centerTitle={true}
          />
        ) : null}

        <span
          className={`grid grid-cols-1 gap-y-3 ${
            this.props.title ? "mt-4" : "mt-0"
          }`}
        >
          {this.props.personalDetails.map((personalDetail) => (
            <PersonalDetailCard
              key={personalDetail.id}
              title={personalDetail.title}
              description={personalDetail.description}
              period={personalDetail.period}
              organization={personalDetail.organization}
              projectUrl={personalDetail.projectUrl}
              techStack={personalDetail.techStack}
              creditsGiven={personalDetail.creditsGiven}
            />
          ))}
        </span>
      </div>
    );
  }
}
