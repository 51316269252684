import { Component } from "react";

type RatingCardProps = {
  progressTitle?: string;
  progressValue: number;
};

type RatingCardState = {};

export default class RatingCard extends Component<
  RatingCardProps,
  RatingCardState
> {
  state = {};

  render() {
    return (
      <span className="w-full">
        <span>{this.props.progressTitle}</span>
        <div className="w-full bg-accent-navy-50 rounded-full border border-accent-navy-75 h-4 mt-2">
          <div
            className="bg-accent-navy-200 h-full rounded-full"
            style={{ width: `${this.props.progressValue}%` }}
          ></div>
        </div>
      </span>
    );
  }
}
