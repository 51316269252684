import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, NavLink } from "react-router-dom";
import LogoIdentifier from "./LogoIdentifier";
import FooterRights from "./FooterRights";
import { Divider } from "@mui/material";
import CommonButton from "./CommonButton";

type Anchor = "right";

export default function Drawer() {
  const [state, setState] = React.useState({
    right: false,
  });

  const activeLink = "cursor-pointer font-bold";
  const normalLink = "cursor-pointer";

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div className="bg-accent-navy-50 w-full h-full p-4">
      <Box
        className="w-full h-full flex flex-col justify-between"
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div>
          <LogoIdentifier />
          <div className="mt-4"></div>
          <Divider />
          <nav className="flex flex-col space-y-4 mb-4">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              About Me
            </NavLink>
            <NavLink
              to="/projects"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Projects
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? activeLink : normalLink)}
            >
              Contact
            </NavLink>
          </nav>
          <Divider />
          <div className="mt-4">
            <Link to={"/hire-me"}>
              <CommonButton title="Hire Me" />
            </Link>
          </div>
        </div>
        <FooterRights />
      </Box>
    </div>
  );

  return (
    <React.Fragment key={"right"}>
      <Button onClick={toggleDrawer("right", true)}>
        <span className="text-accent-navy-200">
          <MenuIcon />
        </span>
      </Button>
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
