import { Component } from "react";

type FooterRightsProps = {};

type FooterRightsState = {
  currentYear: number;
};

export default class FooterRights extends Component<
  FooterRightsProps,
  FooterRightsState
> {
  constructor(props: FooterRightsProps) {
    super(props);
    this.state = {
      currentYear: new Date().getFullYear(),
    };
  }

  render() {
    return (
      <span>
        <p className="text-center">
          All Rights Reserved | {this.state.currentYear} | Yashith Chanuka
        </p>
      </span>
    );
  }
}
