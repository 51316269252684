import { Component } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

type PlatformListProps = {};

type PlatformListState = {};

export default class PlatformList extends Component<
  PlatformListProps,
  PlatformListState
> {
  state = {};

  render() {
    return (
      <span className="flex flex-col items-center md:items-start">
        <span className="mt-2 text-center md:text-left">
          You can find me on below platforms:
        </span>
        <span className="flex space-x-4 mt-2">
          <a
            href="https://www.linkedin.com/in/yashith-chanuka-0897a3173/"
            target={"_blank"}
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://github.com/YashithChanuka"
            target={"_blank"}
            rel="noreferrer"
          >
            <GitHubIcon />
          </a>
          <a
            href="https://www.facebook.com/yashith.chanuka/"
            target={"_blank"}
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/yashithchanuuu/"
            target={"_blank"}
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </span>
      </span>
    );
  }
}
