import { Component } from "react";
import RatingCard from "./RatingCard";
import Title from "./Title";

type RatingSectionProps = {
  title: string;
  titleIcon?: React.ReactNode;
  ratingDetails: {
    id: number;
    title?: string;
    progressValue: number;
  }[];
};

type RatingSectionState = {};

export default class RatingSection extends Component<
  RatingSectionProps,
  RatingSectionState
> {
  state = {};

  render() {
    return (
      <div className="w-full py-4 mt-2">
        <Title
          title={this.props.title}
          titleIcon={this.props.titleIcon}
          centerTitle={true}
        />
        <span className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 gap-8  mt-4 bg-white p-4">
          {this.props.ratingDetails.map((rating) => (
            <RatingCard
              key={rating.id}
              progressTitle={rating.title}
              progressValue={rating.progressValue}
            />
          ))}
        </span>
      </div>
    );
  }
}
