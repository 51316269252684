import { Component } from "react";

type CardProps = {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
};

type CardState = {};

export default class Card extends Component<CardProps, CardState> {
  state = {};

  render() {
    return (
      <span className="bg-white w-full rounded-md px-6 py-4  shadow-md">
        <h4 className="text-center font-heading">
          {this.props.title}&nbsp; {this.props.icon}
        </h4>
        <p className="text-center mt-2 px-2">{this.props.description}</p>
      </span>
    );
  }
}
